<template>
    <v-dialog v-if="show" v-model="show" persistent max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">Login</span>
            </v-card-title>
            <!--                    :lazy-validation="lazy"-->
            <v-form
                    ref="form"
                    v-model="valid"
                    @submit.prevent="login()"
            >
                <v-card-text>
                    <v-container class="no-padding-top-bottom">
                        <v-row v-if="resendVerifyEmail">
                          <v-col v-if="error" cols="12">
                              <div class="error--text">{{error}}</div>
                          </v-col>
                          <v-col>
                            <v-btn
                                small
                                @click="sendConfirmationEmail()"
                                class="margin-right-default"
                                :loading="loadingEmail"
                            >
                              Request verification code
                            </v-btn>
                            <div class="text--secondary margin-top-default">The verification code will be valid during 5 minutes</div>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field id="code" v-model="code" label="Verification Code" required
                                          dense
                                          :rules="verificationCodeRules"
                                          type="number"
                            ></v-text-field>
                          </v-col>

                        </v-row>
                        <v-row v-else>
                          <v-col cols="12">
                              <v-text-field id="email" v-model="email" label="Email" required
                                            :rules="emailRules"
                                            dense
                              ></v-text-field>
                          </v-col>
                          <v-col cols="12" v-if="!forgotPassword">
                              <v-text-field id="password" v-model="password" :rules="passowrdRules" label="Password" type="password"
                                            required
                                            dense
                              ></v-text-field>
                          </v-col>
                          <v-col cols="12" >
                            <div> {{infoMessages}}</div>
                          </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                  <a id="link_reset_password" v-if="!forgotPassword" @click="forgotPassword=true" :disabled="!valid">I forgot my password</a>
                  <a id="link_login" v-if="forgotPassword" @click="forgotPassword=false" :disabled="!valid">Login</a>
                  <v-spacer></v-spacer>
                  <v-btn id="close" color="primary" text @click="closeAction" >Close</v-btn>
                  <!--                    <v-btn color="primary" text @click="users()">Users</v-btn>-->
                  <v-btn id="submit" color="primary" :disabled="!valid" type="submit" :loading="loading" >{{resendVerifyEmail?"Verify":forgotPassword?"Reset my password":"Login"}}</v-btn>
                  <!--                    <v-btn color="primary" :disabled="!valid" @click="loginAsync()">Login async</v-btn>-->
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapMutations } from "vuex";
    import Constants from '../constants';

    export default {
        name: "Login",
      props: {
            show: Boolean,
            closeAction: Function,
        },
        data() {
          return {
            loading:false,
            loadingEmail:false,
            resendVerifyEmail:false,
            forgotPassword:false,
            infoMessages:undefined,
            error:undefined,
            valid: true,
            email: undefined,
            code:undefined,
            password: undefined,
            passowrdRules: [
                v => !!v || 'Password is required',
                v => v!==undefined && v.length >= 4 || 'Password must be at least 4 characters',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            verificationCodeRules:[v => !!v || 'Verification code is required'],
            };
        },
      activated() {
          this.resendVerifyEmail=false;
      },
        methods: {
            ...mapMutations(["showSnackbar", "closeSnackbar"]),
            successfullLogin(){
              this.showSnackbar({ text: Constants.LOGGED_IN ,color:"primary"});
              // console.log('success');
              if (this.$root.actionAfterLogin!==undefined && this.$root.actionAfterLogin==="CHECKOUT"){
                this.$root.actionAfterLogin=undefined;
                this.$router.push({path: `/checkout`})
              }
              if (this.$root.actionAfterLogin!==undefined && this.$root.actionAfterLogin==="REQUEST_DATE"){
                this.$root.actionAfterLogin=undefined;
                this.$router.push({path: `/checkout`})
              }
              if (this.$root.actionAfterLogin!==undefined && this.$root.actionAfterLogin==="LOAD_BOOKING"){
                this.$root.actionAfterLogin=undefined;
                if (this.$store.state.isAdmin==true){
                  this.$router.push({path: `/admin/bookings`})
                }else if (this.$store.state.isChef==true){
                  this.$router.push({path: `/dashboard/bookings`})
                }else if (this.$store.state.loggedin) {
                  this.$router.push("/account/bookings");
                }

              }

              let event={
                'event_category': "click",
                'event_label': "Login"
              }
              if (localStorage && localStorage.getItem("campaign")!==undefined){
                event.campaign=localStorage.getItem("campaign");
              }
              this.$gtag.event("Login", event);
              this.closeAction();
            },
            openSnackbar() {
                this.showSnackbar({ text: Constants.LOGGED_IN ,color:"primary"})
            },
            validate () {
                //this.$refs.form.validate()
            },
          resetPassword(){
              let _this = this;
              this.error = undefined;
              this.loadingEmail=true;
              this.$store.dispatch(
                  'sendForgotEmail',
                  this.email.trim(),
              ).then(function (response) {
                if (response.status === 200) {
                  if (response.data.infoMessages !== undefined) {
                    let text="";
                    Object.values(response.data.infoMessages).forEach(i=>{text+=i+"\n"});
                    _this.showSnackbar({
                      text: text,
                      color: "primary"
                    });
                    _this.infoMessages=text;
                  }
                }
              }).catch(function (error) {
                    // console.log(error);
                    _this.error = error.errorMessage;
                    _this.showSnackbar({text: error.errorMessage, color: "error"});
                  }
              ).finally(()=>{_this.loadingEmail=false});
          },
            login() {
                if (this.resendVerifyEmail){
                  this.verifyEmail();
                  return
                }

              if (this.forgotPassword){
                this.resetPassword();
                return
              }
                let _this = this;
                this.error=undefined;
                // const loginDial=this.loginDialog;
                this.loading=true;
                this.$store.dispatch(
                    'login',
                    {
                        email: this.email.trim(),
                        password: this.password.trim(),
                        "remember-me": true
                    }
                ).then(function () {
                  _this.successfullLogin();
                    // _this.openSnackbar();
                    // _this.closeAction();
                }).catch(function (error) {
                    // console.log(error);
                    _this.error=error.errorMessage;
                    if (error.errorCode==="EMAIL_NOT_VERIFIED"){
                        _this.resendVerifyEmail=true;
                    }
                    _this.showSnackbar({ text: error.errorMessage ,color:"error"});
                    }
                ).finally(()=>{_this.loading=false});
            },
            sendConfirmationEmail() {
                let _this = this;
                this.error = undefined;
                this.loadingEmail=true;
                this.$store.dispatch(
                    'sendConfirmationEmail',
                    this.email.trim(),
                ).then(function (response) {
                    if (response.status === 200) {
                      _this.showSnackbar({
                        text: Constants.CHECK_CONFIRMATION_EMAIL,
                        color: "primary"
                      });
                    }
                }).catch(function (error) {
                        // console.log(error);
                        _this.error = error.errorMessage;
                        _this.showSnackbar({text: error.errorMessage, color: "error"});
                    }
                ).finally(()=>{_this.loadingEmail=false});
            },
            verifyEmail() {
              let _this = this;
              this.error = undefined;
              this.loading=true;
              this.$store.dispatch(
                  'verifyEmail',
                  {email:this.email.trim(),code:parseInt(this.code.trim())}
              ).then(function (response) {
                if (response.status === 200) {
                  if (response.data.infoMessages !==undefined && response.data.infoMessages.EMAIL_CONFIRMED !==undefined){
                    _this.showSnackbar({
                      text: Constants.EMAIL_CONFIRMED,
                      color: "primary"
                    });
                    // run try to login
                    _this.resendVerifyEmail=false;
                    _this.login();
                  }
                  if (response.data.infoMessages !==undefined && response.data.infoMessages.EMAIL_ALREADY_CONFIRMED !==undefined){
                    _this.showSnackbar({
                      text: response.data.infoMessages.EMAIL_ALREADY_CONFIRMED,
                      color: "primary"
                    });
                    // run try to login
                    _this.resendVerifyEmail=false;
                    _this.login();
                  }
                  if (response.data.infoMessages !==undefined && response.data.infoMessages.VERIFICATION_NUMBER_INVALID !==undefined){
                    _this.showSnackbar({
                      text: response.data.infoMessages.VERIFICATION_NUMBER_INVALID,
                      color: "error"
                    });
                    _this.error = response.data.infoMessages.VERIFICATION_NUMBER_INVALID;
                  }
                  if (response.data.infoMessages !==undefined && response.data.infoMessages.VERIFICATION_NUMBER_EXPIRED !==undefined){
                    _this.showSnackbar({
                      text: response.data.infoMessages.VERIFICATION_NUMBER_EXPIRED,
                      color: "error"
                    });
                    _this.error = response.data.infoMessages.VERIFICATION_NUMBER_EXPIRED;
                  }
                }
              }).catch(function (error) {
                    // console.log(error);
                    _this.error = error.errorMessage;
                    _this.showSnackbar({text: error.errorMessage, color: "error"});
                  }
              ).finally(()=>{_this.loading=false});
            },
            async loginAsync() {
                // let _this = this
                // const loginDial=this.loginDialog;
                /*const response =*/ await this.$store.dispatch(
                    'loginAsync',
                    {
                        email: this.email.trim(),
                        password: this.password.trim(),
                        "remember-me": true
                    }
                )
                // console.log(response);
            },
        },
      watch:{
        showLogin: function (val) {
          if (val) {
            this.resendVerifyEmail = false;
            this.forgotPassword = false;
          }
        }
      },
      mounted(){
          // console.log("open login");

      }
    }
</script>

<style scoped>

</style>